import { ConnectButton as Button } from "@rainbow-me/rainbowkit";

const ConnectButton = () => {
  return (
    <Button.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    type="button"
                    className="connectWalletButton"
                  >
                    Connect Wallet
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    className="wrongNetworkButton"
                  >
                    Wrong network
                  </button>
                );
              }

              return (
                <div className="walletConnectButton">
                  <div className="walletbalance">
                    <button
                      onClick={openAccountModal}
                      className="openModalButton"
                      type="button"
                    >
                      {account.displayName}
                      {chain.hasIcon && (
                        <img
                          alt={chain.name ?? "Chain icon"}
                          src={chain.iconUrl}
                          style={{
                            width: 16,
                            height: 16,
                            marginLeft: 8,
                            borderRadius: "50%",
                          }}
                        />
                      )}
                    </button>
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </Button.Custom>
  );
}

export default ConnectButton;