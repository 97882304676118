import React, { useState, useMemo, useEffect } from "react";
import { MaterialReactTable, MRT_ShowHideColumnsButton, MRT_ToggleDensePaddingButton, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, MRT_ToggleGlobalFilterButton, useMaterialReactTable } from "material-react-table";
import { Tooltip, IconButton, Box } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { Link } from "react-router";

const Leaderboard = () => {
  const [nodes, setNodes] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100
  });
  const [columnVisibility, setColumnVisibility] = useState({
    startPoint: false,
    endPoint: false,
    correctnessPoint: false,
    startCounter: false,
    endCounter: false,
    correctnessCounter: false,
  });

  const columns = useMemo(() => [
    {
      accessorKey: "miner",
      header: "Miner",
      Cell: ({ renderedCellValue, row }) => (
        <div className="miner-link">
          <img onClick={() => window.open(`https://sepolia.arbiscan.io/address/${renderedCellValue}`)} src="/link.png" alt="" />
          <Tooltip title={row.original.node_id}>
            <Link to={'/nodestats/' + renderedCellValue} style={{ cursor: 'pointer' }}>{renderedCellValue}</Link>
          </Tooltip>
        </div>
      )
    },
    {
      accessorKey: "globalPingPoint",
      header: <span>GlobalPing<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "ping_counter",
      header: <span>Ping<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "pingPercent",
      header: <span>Ping<br />Percent</span>,
      size: 80,
    },
    {
      accessorKey: "createPoint",
      header: <span>Create<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "createCounter",
      header: <span>Create<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "createPercent",
      header: <span>Create<br />Percent</span>,
      size: 80,
    },
    {
      accessorKey: "preparePoint",
      header: <span>Prepare<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "prepareCounter",
      header: <span>Prepare<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "preparePercent",
      header: <span>Prepare<br />Percent</span>,
      size: 80,
    },
    {
      accessorKey: "startPoint",
      header: <span>Start<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "precommitPoint",
      header: <span>Precommit<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "precommitCounter",
      header: <span>Precommit<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "precommitPercent",
      header: <span>Precommit<br />Percent</span>,
      size: 80,
    },
    {
      accessorKey: "commitPoint",
      header: <span>Commit<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "commitCounter",
      header: <span>Commit<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "commitPercent",
      header: <span>Commit<br />Percent</span>,
      size: 80,
    },
    {
      accessorKey: "requestPoint",
      header: <span>Request<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "requestCounter",
      header: <span>Request<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "requestPercent",
      header: <span>Request<br />Percent</span>,
      size: 80,
    },
    {
      accessorKey: "endPoint",
      header: <span>End<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "correctnessPoint",
      header: <span>Correctness<br />Point</span>,
      size: 80,
    },
    {
      accessorKey: "startCounter",
      header: <span>Start<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "endCounter",
      header: <span>End<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "correctnessCounter",
      header: <span>Correctness<br />Counter</span>,
      size: 80,
    },
    {
      accessorKey: "node_id",
      header: "ID"
    },
  ], []);

  useEffect(() => {
    setLoading(true);

    // const leaderboardPromise = fetch('/leaderboard')
    const leaderboardPromise = fetch('https://lb-be.cortensor.network/leaderboard')
      .then((res) => res.json())
      .then((data) => {
        data.forEach((node, key) => {
          data[key]['pingPercent'] = node.ping_counter > 0 ? Math.floor(node.globalPingPoint / node.ping_counter * 100) + '%' : 'N/A';
          data[key]['requestPercent'] = node.requestCounter > 0 ? Math.floor(node.requestPoint / node.requestCounter * 100) + '%' : 'N/A';
          data[key]['createPercent'] = node.createCounter > 0 ? Math.floor(node.createPoint / node.createCounter * 100) + '%' : 'N/A';
          data[key]['preparePercent'] = node.prepareCounter > 0 ? Math.floor(node.preparePoint / node.prepareCounter * 100) + '%' : 'N/A';
          data[key]['startPercent'] = node.startCounter > 0 ? Math.floor(node.startPoint / node.startCounter * 100) + '%' : 'N/A';
          data[key]['endPercent'] = node.endCounter > 0 ? Math.floor(node.endPoint / node.endCounter * 100) + '%' : 'N/A';
          data[key]['precommitPercent'] = node.precommitCounter > 0 ? Math.floor(node.precommitPoint / node.precommitCounter * 100) + '%' : 'N/A';
          data[key]['commitPercent'] = node.commitCounter > 0 ? Math.floor(node.commitPoint / node.commitCounter * 100) + '%' : 'N/A';
          data[key]['correctnessPercent'] = node.correctnessCounter > 0 ? Math.floor(node.correctnessPoint / node.correctnessCounter * 100) + '%' : 'N/A';
        });
        setNodes(data);
        table.reset();
      })
      .catch(console.error);

    // const statsPromise = fetch('/stats')
    const statsPromise = fetch('https://lb-be.cortensor.network/stats')
      .then((res) => res.json())
      .then((data) => {
        setStats({
          counters: data.total_counters,
          nodes: data.total_nodes,
          points: data.total_points
        });
      })
      .catch(console.error);

    Promise.all([leaderboardPromise, statsPromise]).finally(() => setLoading(false));
  }, []);

  const table = useMaterialReactTable({
    data: nodes,
    columns,
    state: {
      columnVisibility,
      pagination
    },
    initialState: {
      sorting: [
        { id: 'ping_counter', desc: false }, // Sort by 'ping_counter' in ascending order
        { id: 'requestPoint', desc: false }, // Sort by 'requestPoint' in descending order
      ],
      density: 'compact',
    },
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    isMultiSortEvent: () => true,
    renderTopToolbarCustomActions: () => {
      return (
        <div className="table-stats" style={{ display: 'flex', gap: '20px', paddingLeft: '10px' }}>
          <div>Total nodes: <strong>{stats.nodes || 'N/A'}</strong></div>
          <div>Total counters: <strong>{stats.counters || 'N/A'}</strong></div>
          <div>Total points: <strong>{stats.points || 'N/A'}</strong></div>
          <div>Total percent: <strong>{isNaN(stats.points / stats.counters * 100) ? 'N/A' : (stats.points / stats.counters * 100).toFixed(2) + '%'}</strong></div>
        </div>
      )
    },
    renderToolbarInternalActions: () => {
      return (
        <Box>
          <MRT_ToggleGlobalFilterButton table={table} />
          <Tooltip title="Clear sorting">
            <IconButton onClick={() => table.resetSorting(true)}>
              <Clear />
            </IconButton>
          </Tooltip>
          <MRT_ToggleFiltersButton table={table} />
          <MRT_ShowHideColumnsButton table={table} />
          <MRT_ToggleDensePaddingButton table={table} />
          <MRT_ToggleFullScreenButton table={table} />
        </Box>
      )
    },
  });

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="sessions-container">
      <div className="sessions-list">
        <div className="session-item">
          {/* <div className="session-header">
            <h4>Leaderboard</h4>
          </div> */}

          <div className="leaderboard-container">
            <MaterialReactTable table={table} />
          </div>
        </div>
      </div>

    </div>
  );
}

export default Leaderboard;