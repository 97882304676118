import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useAccount } from "wagmi";
import { RefreshCw, Search } from 'lucide-react';
import CognitiveABI from "../abi/Cognitive.json";
import Button from "../components/Button";
import { COGNITIVE_ADDRESS, PUBLIC_RPC_URL } from "../lib/config";
import { useParams, useNavigate, Link } from "react-router";

const Cognitive = () => {
  const { isConnected } = useAccount();
  const [cognitiveContract, setCognitiveContract] = useState(null);
  const [cognitiveSessions, setCognitiveSessions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [latestSessionId, setLatestSessionId] = useState(0);
  const [lookupSession, setLookupSession] = useState("");
  const { sessionId: sessionIdString } = useParams();
  const sessionId = Number(sessionIdString);
  const navigate = useNavigate();

  const changeSession = (id) => {
    navigate('/cognitive/' + id);
  }

  const handleSessionLookup = () => {
    changeSession(lookupSession);
  }

  const getSessionStateText = (state) => {
    const states = {
      0: 'Requested',
      1: 'Created',
      2: 'Prepared',
      3: 'Active',
      4: 'Precommitted',
      5: 'Committed',
      6: 'Ended'
    };
    return states[state] || 'Unknown';
  };

  const fetchData = () => {
    if (!cognitiveContract || !latestSessionId) return;
    if (!sessionId) return changeSession(latestSessionId);
    setLoading(true);
    cognitiveContract.get(sessionId).then(setCognitiveSessions).catch(error => {
      console.error("Error fetching data:", error);
      setError(`Failed to fetch data: ${error.message}`);
    }).finally(() => {
      setLoading(false);
    });
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp || timestamp === 0) return 'N/A';
    return new Date(Number(timestamp) * 1000).toLocaleString();
  };

  useEffect(() => {
    const provider = isConnected ? new ethers.BrowserProvider(window.ethereum) : new ethers.JsonRpcProvider(PUBLIC_RPC_URL);
    const cognitive = new ethers.Contract(COGNITIVE_ADDRESS, CognitiveABI, provider);
    setCognitiveContract(cognitive);
  }, [isConnected]);

  useEffect(() => {
    if (!cognitiveContract) return;
    cognitiveContract.getLatestSessionId().then(latestId => setLatestSessionId(Number(latestId))).catch(error => {
      console.error(error);
      setError(error.message);
    });
  }, [cognitiveContract]);

  useEffect(() => {
    if (sessionIdString === undefined) {
      const before = sessionStorage.getItem('sessionId');
      if (isNaN(Number(before))) return;
      else changeSession(before);
    } else sessionStorage.setItem('sessionId', sessionIdString);
  }, [sessionIdString]);

  useEffect(fetchData, [sessionId, cognitiveContract, latestSessionId]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!cognitiveSessions) return <p>No cognitive sessions available.</p>;

  return (
    <div>
      <div className="refresh-section">
        <div className="refresh-content">
          <div className="session-info">
            <p className="latest-session">
              Latest Session ID: <strong>{latestSessionId}</strong>
            </p>
            <div className="session-lookup">
              <input
                type="text"
                value={lookupSession}
                onChange={e => setLookupSession(e.target.value)}
                placeholder="Enter session ID to lookup"
                className="session-input"
              />
              <Search onClick={handleSessionLookup} className="icon" style={{ cursor: 'pointer' }} />
            </div>
          </div>
          <div className="pagination-buttons">
            <a
              onClick={() => window.open(`http://twitter.com/share?text=${encodeURIComponent('Mining dashboard.')}&url=${encodeURIComponent(window.location.href)}`)}
              className="btn pagination-btn link-button"
            >
              Share
            </a>
            <Button
              onClick={() => changeSession(latestSessionId)}
              disabled={sessionId === latestSessionId}
              className="pagination-btn hover"
            >
              Latest
            </Button>
            <Button
              onClick={() => changeSession(sessionId + 1)}
              disabled={sessionId >= latestSessionId}
              className="pagination-btn"
            >
              Next
            </Button>
            <Button
              onClick={() => changeSession(sessionId - 1)}
              disabled={sessionId === 1}
              className="pagination-btn"
            >
              Previous
            </Button>
            <Button
              onClick={() => changeSession(1)}
              disabled={sessionId === 1}
              className="pagination-btn hover"
            >
              First
            </Button>
            <Button onClick={fetchData} className="refresh-button">
              <RefreshCw className="icon" />
              Refresh
            </Button>
          </div>
        </div>
      </div>

      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}

      <div className="sessions-container">
        <div className="sessions-list">
          {cognitiveSessions &&
            <div className="session-item">
              <div className="session-header">
                <h4>Session #{cognitiveSessions[0].toString()}</h4>
                <p>
                  <strong>Status:</strong>
                  {getSessionStateText(Number(cognitiveSessions[8]))}
                </p>
              </div>

              <div className="two-column-grid">
                {/* Left Column */}
                <div className="column">
                  {/* Basic Info */}
                  <div className="info-group">
                    <h5>Session Details</h5>
                    <p>
                      <strong>ID:</strong> {cognitiveSessions[0].toString()}
                    </p>
                    <p>
                      <strong>Created:</strong> {formatTimestamp(cognitiveSessions[1])}
                    </p>
                    <p>
                      <strong>Started:</strong> {formatTimestamp(cognitiveSessions[9])}
                    </p>
                    <p>
                      <strong>Ended:</strong> {formatTimestamp(cognitiveSessions[10])}
                    </p>
                  </div>

                  {/* Participants Information */}
                  <div className="info-group">
                    <h5>Participants</h5>
                    <p>
                      <strong>Creator:</strong> <Link to={'/nodestats/' + cognitiveSessions[11]}>{cognitiveSessions[11]}</Link>
                    </p>
                    <div>
                      <strong>Preparers:</strong>
                      <ul>
                        {cognitiveSessions[12] &&
                          cognitiveSessions[12].map((preparer, idx) => (
                            <p><Link to={'/nodestats/' + preparer} key={idx}>{preparer}</Link></p>
                          ))}
                      </ul>
                    </div>
                    <p>
                      <strong>Preparer Index:</strong>
                      {cognitiveSessions[13].toString()}
                    </p>
                    <div>
                      <strong>Miners:</strong>
                      <ul>
                        {cognitiveSessions[7] &&
                          cognitiveSessions[7].map((miner, idx) => (
                            <p><Link to={'/nodestats/' + miner} key={idx}>{miner}</Link></p>
                          ))}
                      </ul>
                    </div>
                    <div>
                      <strong>Validators:</strong>
                      <ul>
                        {cognitiveSessions[18] &&
                          cognitiveSessions[18].map((validator, idx) => (
                            <li key={idx}>{validator}</li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {/* Submission Details */}
                  <div className="info-group">
                    <h5>Submission Details</h5>
                    <p>
                      <strong>Completion Counter:</strong>
                      {cognitiveSessions[17].toString()}
                    </p>
                    <div>
                      <strong>Submitter IDs:</strong>
                      <ul>
                        {cognitiveSessions[14] &&
                          cognitiveSessions[14].map((id, idx) => (
                            <li key={idx}>{id}</li>
                          ))}
                      </ul>
                    </div>
                  </div>

                </div>

                {/* Right Column */}
                <div className="column">
                  {/* Task Information */}
                  <div className="info-group">
                    <h5>Task Information</h5>
                    <p>
                      <strong>Domain:</strong> {cognitiveSessions[2]}
                    </p>
                    <p>
                      <strong>Subdomain:</strong> {cognitiveSessions[3]}
                    </p>
                    <p>
                      <strong>Max Prompts:</strong> {cognitiveSessions[4].toString()}
                    </p>
                    <p>
                      <strong>Challenge Index:</strong> {cognitiveSessions[5].toString()}
                    </p>
                    <p>
                      <strong>Challenge Number:</strong> {(parseInt(cognitiveSessions[5]) + 1).toString()}
                    </p>
                    <div className="challenge-list">
                      <strong>Challenges:</strong>
                      <ul>
                        {cognitiveSessions[6] &&
                          cognitiveSessions[6].map((challenge, idx) => (
                            <li key={idx}>{challenge}</li>
                          ))}
                      </ul>
                    </div>
                    <div className="completions-list">
                      <strong>Outputs:</strong>
                      <ul>
                        {cognitiveSessions[16] &&
                          cognitiveSessions[16].map((completion, idx) => (
                            <li key={idx}>
                              <strong>Output {idx + 1}:</strong>
                              <p>{completion}</p>
                              <small>
                                Hash: {cognitiveSessions[15] && cognitiveSessions[15][idx]}
                              </small>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          }
        </div>

      </div>
    </div>
  );
}

export default Cognitive;